/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IAdmissionTestType,
  IApplicationFee,
  ICountryISO,
  ICountryResidencyStatuses,
  ICountrySACC,
  ICourse,
  IErrorResponse,
  IInstitution,
  IPicklistValue,
  ISecondaryQualificationType,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class ReferenceDataApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Cached for 1 minute.
   *
   * @tags Reference Data API
   * @name GetCountryResidencyStatus
   * @summary Get a list of countries and their residency statuses the current agent has access to
   * @request GET:/api/reference-data/country-residency-status
   * @response `200` `(ICountryResidencyStatuses)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getCountryResidencyStatus = (params: RequestParams = {}) =>
    this.request<ICountryResidencyStatuses[], IErrorResponse>({
      path: `/api/reference-data/country-residency-status`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetInstitutionsList
   * @summary Get a list of institutions for a specific country
   * @request GET:/api/reference-data/institutions-list
   * @response `200` `(IInstitution)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getInstitutionsList = (
    query: {
      /** The type of institution (secondary/tertiary) to retrieve. */
      type: "Secondary" | "Tertiary";
      /** ISO country name. */
      country: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<IInstitution[], IErrorResponse>({
      path: `/api/reference-data/institutions-list`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Reference Data API
   * @name GetApplicationFees
   * @summary Get the application fees for the agent's agency
   * @request GET:/api/reference-data/application-fees
   * @response `200` `(IApplicationFee)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicationFees = (params: RequestParams = {}) =>
    this.request<IApplicationFee[], IErrorResponse>({
      path: `/api/reference-data/application-fees`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetApplicationType
   * @summary Get application types
   * @request GET:/api/reference-data/application-type
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicationType = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/application-type`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Reference Data API
   * @name GetSalutations
   * @summary Get a list of salutations
   * @request GET:/api/reference-data/salutations
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getSalutations = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/salutations`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetGenders
   * @summary Get a list of genders
   * @request GET:/api/reference-data/genders
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getGenders = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/genders`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetPronouns
   * @summary Get a list of pronouns
   * @request GET:/api/reference-data/pronouns
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getPronouns = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/pronouns`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetScholarshipSponsorshipStatuses
   * @summary Get a list of scholarship/sponsorship statuses
   * @request GET:/api/reference-data/scholarship-sponsorship-statuses
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getScholarshipSponsorshipStatuses = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/scholarship-sponsorship-statuses`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetVisaTypes
   * @summary Get a list of visa types
   * @request GET:/api/reference-data/visa-types
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getVisaTypes = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/visa-types`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetQualificationStatuses
   * @summary Get a list of academic qualification statuses
   * @request GET:/api/reference-data/qualification-statuses
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getQualificationStatuses = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/qualification-statuses`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetHasDisabilities
   * @summary Get responses for has disabilities
   * @request GET:/api/reference-data/has-disabilities
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getHasDisabilities = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/has-disabilities`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetExistingDisabilitySupport
   * @summary Get responses for existing disability support
   * @request GET:/api/reference-data/existing-disability-support
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getExistingDisabilitySupport = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/existing-disability-support`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetRefusedEntryVisaToAnyCountry
   * @summary Get responses for refused entry visa to any country
   * @request GET:/api/reference-data/refused-entry-visa-to-any-country
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getRefusedEntryVisaToAnyCountry = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/refused-entry-visa-to-any-country`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetBreachedVisaCoundition
   * @summary Get responses for breached visa condition
   * @request GET:/api/reference-data/breached-visa-condition
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getBreachedVisaCoundition = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/breached-visa-condition`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetMedicalHealthPreventVisa
   * @summary Get responses for medical health prevent visa
   * @request GET:/api/reference-data/medical-health-prevent-visa
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getMedicalHealthPreventVisa = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/medical-health-prevent-visa`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetProtectionVisaInAnyCountry
   * @summary Get responses for get protection visa in any country
   * @request GET:/api/reference-data/protection-visa-in-any-country
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getProtectionVisaInAnyCountry = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/protection-visa-in-any-country`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetConvictedOfCrimeOffence
   * @summary Get responses for convicted of crime offence
   * @request GET:/api/reference-data/convicted-of-crime-offence
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getConvictedOfCrimeOffence = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/convicted-of-crime-offence`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetStudentPassAvailability
   * @summary Get responses for student pass availability
   * @request GET:/api/reference-data/student-pass-availability
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getStudentPassAvailability = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/student-pass-availability`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetEnglishLanguageProficiency
   * @summary Get responses for English language proficiency
   * @request GET:/api/reference-data/english-language-proficiency
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getEnglishLanguageProficiency = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/english-language-proficiency`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetApplicationFeePaymentModes
   * @summary Get application fee payment modes
   * @request GET:/api/reference-data/application-fee-payment-modes
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicationFeePaymentModes = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/application-fee-payment-modes`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetQualificationTypes
   * @summary Get a list of admission test types
   * @request GET:/api/reference-data/qualification-types
   * @response `200` `(IPicklistValue)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getQualificationTypes = (params: RequestParams = {}) =>
    this.request<IPicklistValue[], IErrorResponse>({
      path: `/api/reference-data/qualification-types`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetAdmissionTestTypes
   * @summary Get a list of admission test types
   * @request GET:/api/reference-data/qualification-types/admission-test
   * @response `200` `(IAdmissionTestType)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getAdmissionTestTypes = (params: RequestParams = {}) =>
    this.request<IAdmissionTestType[], IErrorResponse>({
      path: `/api/reference-data/qualification-types/admission-test`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetSecondaryQualificationTypes
   * @summary Get a list of secondary qualification types
   * @request GET:/api/reference-data/qualification-types/secondary-qualification
   * @response `200` `(ISecondaryQualificationType)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getSecondaryQualificationTypes = (
    query: {
      isOverseas: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ISecondaryQualificationType[], IErrorResponse>({
      path: `/api/reference-data/qualification-types/secondary-qualification`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetCountriesIso
   * @summary Get the list of countries (ISO)
   * @request GET:/api/reference-data/countries/iso
   * @response `200` `(ICountryISO)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getCountriesIso = (params: RequestParams = {}) =>
    this.request<ICountryISO[], IErrorResponse>({
      path: `/api/reference-data/countries/iso`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetCountriesSacc
   * @summary Get the list of countries (SACC)
   * @request GET:/api/reference-data/countries/sacc
   * @response `200` `(ICountrySACC)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getCountriesSacc = (params: RequestParams = {}) =>
    this.request<ICountrySACC[], IErrorResponse>({
      path: `/api/reference-data/countries/sacc`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetCourses
   * @summary Get the available courses for an application
   * @request GET:/api/reference-data/courses
   * @response `200` `(ICourse)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getCourses = (
    query: {
      citizenshipTypes?: string[];
      pathwayCourseCode?: string;
      courseName: string;
      /** @example "Australia" */
      campusCountry: "Australia" | "Malaysia" | "South Africa" | "Indonesia" | "Online";
      /** @example 2024 */
      courseYear: number;
      /** @example "Direct" */
      courseType?: "Direct" | "Pathway" | "English";
    },
    params: RequestParams = {},
  ) =>
    this.request<ICourse[], IErrorResponse>({
      path: `/api/reference-data/courses`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetCourseByCourseCode
   * @summary Get a specific course by its course code
   * @request GET:/api/reference-data/courses/{courseCode}
   * @response `200` `ICourse`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` Course not found
   */
  getCourseByCourseCode = (
    courseCode: string,
    query: {
      courseYear: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ICourse, IErrorResponse>({
      path: `/api/reference-data/courses/${courseCode}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetEnglishCourseCodes
   * @summary Get the list of English Course codes
   * @request GET:/api/reference-data/englishCourseCodes
   * @response `200` `(string)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getEnglishCourseCodes = (params: RequestParams = {}) =>
    this.request<string[], IErrorResponse>({
      path: `/api/reference-data/englishCourseCodes`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Cached for 1 hour.
   *
   * @tags Reference Data API
   * @name GetMonashCollegeFaculties
   * @summary Get the list of Monash College Faculty names
   * @request GET:/api/reference-data/monashCollegeFaculties
   * @response `200` `(string)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getMonashCollegeFaculties = (params: RequestParams = {}) =>
    this.request<string[], IErrorResponse>({
      path: `/api/reference-data/monashCollegeFaculties`,
      method: "GET",
      format: "json",
      ...params,
    });
}
