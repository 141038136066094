import { Grid, MenuItem } from "@mui/material";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { useMemo, useState } from "react";

import { list } from "@packages/utils";
import { CourseCampusCountry, CourseType } from "@packages/types";

import { ApplicationFields } from "~/components/application/ApplicationForm";
import { FormSection } from "~/components/form/FormSection";
import { FormAutocomplete } from "~/components/form/FormAutocomplete";
import { FormSelect } from "~/components/form/FormSelect";
import { useCourses } from "~/hooks/reference-data";
import {
  CoursePreference,
  SelectedCourse,
} from "~/components/application/ApplicationForm/CoursePreferencesForm";

import { CoursePreferenceFormFields, getCourseOptions } from "./_utils";

export interface CourseSelectionFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  fields: CoursePreferenceFormFields;
  title?: React.ReactNode;
  numberOfYears?: number;
  residencyStatus: string | null;
  campusCountry: CourseCampusCountry | null;
  courseType: CourseType;
  coursePreference?: CoursePreference | null;
  filterByPathwayCourse?: boolean;
  disabled?: boolean;
}

export function CourseSelectionForm(props: CourseSelectionFormProps) {
  const {
    control,
    fields,
    title = "Course Preference",
    numberOfYears = 4,
    campusCountry,
    residencyStatus,
    courseType,
    coursePreference,
    setValue,
    filterByPathwayCourse,
    disabled,
  } = props;

  const {
    courseInfo,
    offeringAcademicYear,
    stream = "",
    studyPeriod = "",
    location = "",
  } = coursePreference ?? {};

  const streams = useMemo(
    () => (coursePreference ? getCourseOptions(coursePreference) : {}),
    [coursePreference],
  );

  const [query, setQuery] = useState("");
  const [courseName] = useDebounce(query, 1000);

  const [pathwayCourse] = useWatch({
    control,
    name: ["coursePreferences.pathwayCourse"],
  });

  const courses = useCourses({
    campusCountry,
    citizenshipType: residencyStatus,
    courseType,
    courseYear: Number(offeringAcademicYear),
    courseName:
      // Always search "English" when the course type is English
      courseType === CourseType.English
        ? "English"
        : // When a course is displayed with its course code, don't pass the
          // course code in the query or it'll return empty which may be confusing
          // "E6014 – Master of Engineering" -> "Master of Engineering"
          (courseName.split("–").at(-1)?.trim() ?? ""),
    pathwayCourseCode:
      courseType === CourseType.Pathway && filterByPathwayCourse && pathwayCourse
        ? pathwayCourse.courseCode
        : undefined,
  });

  const studyPeriods = streams[stream]?.studyPeriods ?? {};
  const locations = studyPeriods?.[studyPeriod]?.locations ?? {};

  const onResetCourse = () => setValue?.(fields.courseInfo, null);
  const onSetCourseCode = (code: string) => setValue?.(fields.courseCode, code);
  const onResetCourseCode = () => setValue?.(fields.courseCode, "");
  const onResetSpecialisation = () => setValue?.(fields.stream, "");
  const onResetStudyPeriod = () => setValue?.(fields.studyPeriod, "");
  const onResetCampus = () => setValue?.(fields.location, "");

  const onResetCourseDetails = () => {
    onResetCourse();
    onResetCourseCode();
    onResetSpecialisation();
    onResetStudyPeriod();
    onResetCampus();
  };

  const year = new Date().getFullYear();

  const courseOptions: SelectedCourse[] =
    (courseType === CourseType.English
      ? // Always show results when the course type is English
        courses.data
      : // Otherwise, only show results if there is a non-empty query
        query.length > 0
        ? courses.data
        : null) ?? [];

  const disableCourseSearch = !residencyStatus || !campusCountry || disabled;

  return (
    <>
      <Grid item xs={12} mt={1}>
        <FormSection.Subheading gutterBottom={false} mt={1}>
          {title}
        </FormSection.Subheading>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSelect
          control={control}
          name={fields.courseYear}
          label="Course Year"
          disabled={disabled}
          onBeforeChange={() => onResetCourseDetails()}
        >
          {list(numberOfYears).map((key) => (
            <MenuItem key={key} value={String(year + key)}>
              {year + key}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12}>
        <FormAutocomplete
          control={control}
          name={fields.courseInfo}
          label={courseType === CourseType.English ? "English course" : "Course"}
          inputValue={query}
          onInputChange={(e, courseName) =>
            disableCourseSearch ? e?.preventDefault() : setQuery(courseName)
          }
          onBeforeChange={(selectedCourse) => {
            if (selectedCourse?.code !== courseInfo?.code) {
              onResetCourseDetails();
              onSetCourseCode(selectedCourse?.code ?? "");
            }
          }}
          options={courseOptions}
          placeholder="Search by course name"
          getOptionLabel={(option) => (option ? `${option.code} – ${option.title}` : "")}
          loading={courses.isFetching || (query.length >= 3 && query !== courseName)}
          loadingText="Loading..."
          noOptionsText={
            courseType !== CourseType.English && query.length < 3
              ? "Enter three or more characters to search..."
              : "No results"
          }
          defaultValue={null}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
          disabled={disableCourseSearch}
          helperText={
            !residencyStatus || !campusCountry
              ? "Please select a citizenship status before choosing a course"
              : ""
          }
        />
      </Grid>
      {courseInfo && (
        <>
          <Grid item xs={12} sm={8}>
            <FormSelect
              control={control}
              name={fields.stream}
              label={courseType === CourseType.English ? "Stream" : "Specialisation"}
              disabled={disabled}
              displayEmpty={"" in streams ? true : undefined}
              onBeforeChange={(e) => {
                const stream = e.target.value as string;
                const studyPeriods = streams[stream]?.studyPeriods ?? {};
                const locations = studyPeriods?.[studyPeriod]?.locations ?? {};
                if (!(studyPeriod in studyPeriods)) onResetStudyPeriod();
                if (!(location in locations)) onResetCampus();
              }}
            >
              {Object.entries(streams).map(([code, stream]) => (
                <MenuItem key={code} value={code}>
                  {stream?.label}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              control={control}
              name={fields.studyPeriod}
              label="Semester"
              disabled={disabled || Object.keys(studyPeriods).length === 0}
              onBeforeChange={(e) => {
                const studyPeriod = e.target.value as string;
                const studyPeriods = streams[stream]?.studyPeriods ?? {};
                const campuses = studyPeriods?.[studyPeriod]?.locations ?? {};
                if (!(location in campuses)) onResetCampus();
              }}
            >
              {Object.entries(studyPeriods).map(([code, studyPeriod]) => (
                <MenuItem key={code} value={code}>
                  {studyPeriod?.label}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              control={control}
              name={fields.location}
              label="Location"
              disabled={disabled || Object.keys(locations).length === 0}
            >
              {Object.entries(locations).map(([code, location]) => (
                <MenuItem key={code} value={code}>
                  {location?.label}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </>
      )}
    </>
  );
}
