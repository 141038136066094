/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IApplicant, IApplication, IErrorResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class SampleDataApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Generate a sample applicant for testing.
   *
   * @tags Sample Data API
   * @name GetApplicantSample
   * @request GET:/api/admin/data/applicant
   * @response `200` `IApplicant`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicantSample = (params: RequestParams = {}) =>
    this.request<IApplicant, IErrorResponse>({
      path: `/api/admin/data/applicant`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Generate a sample application for testing.
   *
   * @tags Sample Data API
   * @name GetApplicationSample
   * @request GET:/api/admin/data/application
   * @response `200` `IApplication`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicationSample = (
    query: {
      campusLocation?: string;
      residencyStatus?: string;
      applicationId: string;
      applicantId: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<IApplication, IErrorResponse>({
      path: `/api/admin/data/application`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
