import { format as formatDate, formatRelative } from "date-fns";

import { getLocalTimeZoneName } from "@packages/utils";

export interface TimestampProps {
  children?: Date | string | number | undefined | null;

  /**
   * Format to ouptut the timestamp in.
   *
   * @see https://date-fns.org/v2.29.3/docs/format
   */
  format?:
    | "d MMMM yyyy"
    | "d/MM/yyyy"
    | "d/MM/yyyy h:mm aaa"
    | "yyyy-MM-dd HH:mm:ss O"
    | "cccc h:mm aa, do MMMM yyyy timezone"
    | "h:mm aaa"
    | "h:mm:ss aaa"
    | "localeString"
    | "localeDateString"
    | "localeTimeString"
    | "ISOString"
    | "relative";

  defaultValue?: string;
}

export function Timestamp(props: TimestampProps) {
  const { children: value, format = "d/MM/yyyy h:mm aaa", defaultValue = null } = props;

  const date =
    value instanceof Date
      ? value
      : typeof value === "string" || typeof value === "number"
        ? new Date(value)
        : null;

  // Don't attepmt to format invalid dates
  if (value && date && isNaN(date.getTime())) return <>{value}</>;

  // If the date is empty, return the default value
  if (!date || !value) return <>{defaultValue}</>;

  switch (format) {
    case "relative":
      return <>{formatRelative(date, new Date())}</>;
    case "h:mm aaa":
    case "h:mm:ss aaa":
    case "d/MM/yyyy":
    case "d MMMM yyyy":
    case "yyyy-MM-dd HH:mm:ss O":
    case "d/MM/yyyy h:mm aaa":
      return <>{formatDate(date, format)}</>;
    case "cccc h:mm aa, do MMMM yyyy timezone":
      return (
        <>
          {formatDate(
            date,
            `cccc h:mm aa, do MMMM yyyy '${getLocalTimeZoneName("long", date)}'`,
          )}
        </>
      );
    case "localeString":
      return <>{date.toLocaleString()}</>;
    case "localeDateString":
      return <>{date.toLocaleDateString()}</>;
    case "localeTimeString":
      return <>{date.toLocaleTimeString()}</>;
    case "ISOString":
    default:
      return <>{date.toISOString()}</>;
  }
}
