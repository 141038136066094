/**
 * Returns the name of the time zone on the current machine.
 */
export function getLocalTimeZoneName(
  timeZoneName: Intl.DateTimeFormatOptions["timeZoneName"] = "short",
  date: Date,
) {
  const output = new Intl.DateTimeFormat("en-AU", { timeZoneName }).format(date);
  const [, timeZone] = output.split(",");
  return timeZone?.trim() ?? "";
}

const MS_IN_SEC = 1000;
const SECS_IN_MIN = 60;
const MINS_IN_HR = 60;

const pad = (value: number, digits = 2) => value.toString().padStart(digits, "0");

/**
 * Convert a value in milliseconds to the total duration in milliseconds, seconds, minutes and hours.
 */
export function toDurationTotals(ms: number) {
  const milliseconds = ms;
  const seconds = ms / MS_IN_SEC;
  const minutes = ms / MS_IN_SEC / SECS_IN_MIN;
  const hours = ms / MS_IN_SEC / SECS_IN_MIN / MINS_IN_HR;
  return { milliseconds, seconds, minutes, hours };
}

/**
 * Return the duration components for a given duration in milliseconds.
 */
export function toDurationComponents(ms: number) {
  const duration = toDurationTotals(ms);
  const milliseconds = Math.floor(duration.milliseconds % MS_IN_SEC);
  const seconds = Math.floor(duration.seconds % SECS_IN_MIN);
  const minutes = Math.floor(duration.minutes % MINS_IN_HR);
  const hours = Math.floor(duration.hours % MINS_IN_HR);
  return { milliseconds, seconds, minutes, hours };
}

export type DurationFormat = "ms" | "s" | "m:ss" | "mm:ss" | "h:mm:ss" | "pretty";

/**
 * Truncates a number to the specified number of digits.
 */
function truncate(value: number, digits: number = 0) {
  return Number(value.toFixed(digits));
}

/**
 * Format the given duration to the specified format.
 */
export function formatDuration(
  durationInMs: number,
  format: DurationFormat = "pretty",
): string {
  const total = toDurationTotals(durationInMs);

  if (format === "ms") return total.milliseconds.toString();
  if (format === "s") return total.seconds.toString();

  const M = Math.floor(total.minutes);
  const MM = pad(M);

  const duration = toDurationComponents(durationInMs);
  const h = duration.hours;
  const m = duration.minutes;
  const s_ms = truncate(duration.seconds + duration.milliseconds / MS_IN_SEC, 3);
  const mm = pad(m);
  const ss = pad(Math.round(s_ms));

  if (format === "m:ss") return `${M}:${ss}`;
  if (format === "mm:ss") return `${MM}:${ss}`;
  if (format === "h:mm:ss") return `${h}:${mm}:${ss}`;

  if (format === "pretty") {
    const s = duration.seconds;
    const ms = duration.milliseconds;
    if (h > 0) return `${h}h ${mm}m ${ss}s`;
    if (m > 0) return `${m}m ${ss}s`;
    if (s > 0) return `${s_ms}s`;
    return `${ms}ms`;
  }

  throw Error(`Invalid format: ${format}`);
}

/**
 * Format the given number of seconds.
 */
export function formatSeconds(seconds: number, format?: DurationFormat): string {
  return formatDuration(seconds * MS_IN_SEC, format);
}
