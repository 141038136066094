/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IErrorResponse, IGetLogEntriesResponseDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class SupportApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get application error logs.
   *
   * @tags Support API
   * @name GetErrorLogs
   * @request GET:/api/admin/error-logs
   * @response `200` `IGetLogEntriesResponseDTO`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getErrorLogs = (
    query?: {
      pageToken?: string;
      /** @example 100 */
      pageSize?: number;
      correlationId?: string;
      /** @format date-time */
      after?: string;
      /** @format date-time */
      before?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<IGetLogEntriesResponseDTO, IErrorResponse>({
      path: `/api/admin/error-logs`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Get logs for requests made to MIX.
   *
   * @tags Support API
   * @name GetMixLogs
   * @request GET:/api/admin/mix-logs
   * @response `200` `IGetLogEntriesResponseDTO`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getMixLogs = (
    query?: {
      pageToken?: string;
      /** @example 100 */
      pageSize?: number;
      correlationId?: string;
      /** @format date-time */
      after?: string;
      /** @format date-time */
      before?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<IGetLogEntriesResponseDTO, IErrorResponse>({
      path: `/api/admin/mix-logs`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
