import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { Container } from "@mui/material";

import {
  ErrorAction,
  parseRequestError,
  parseRouteError,
  parseAppError,
  AppNotFoundError,
} from "~/utils";
import { useDocumentTitle } from "~/hooks/title";
import { Navigation } from "~/components/core/Navigation";
import { ErrorMessageCard, ErrorMessageCardProps } from "~/components/core/ErrorMessage";

export function ErrorBoundary() {
  const error = useRouteError();
  const requestError = parseRequestError(error);
  const routeError = parseRouteError(error);
  const appError = parseAppError(error);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [titleSize, setTitleSize] = useState<ErrorMessageCardProps["titleSize"]>("large");
  const [message, setMessage] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [actions, setActions] = useState<ErrorAction[]>([]);

  useDocumentTitle(title);

  useEffect(() => {
    // send error to Sentry
    Sentry.captureException(error, {
      tags: { source: "ErrorBoundary" },
    });

    // Display app errors
    if (appError) {
      const { message, info } = appError;
      const { code, title, actions } = info;
      if (appError instanceof AppNotFoundError) {
        // Handle defaults for a custom 404 error
        setCode(String(code || 404));
        setTitle(title || "Not Found");
        setMessage(message || `Error: No route matches URL "${pathname}"`);
        setActions(actions ?? ["back"]);
        setTitleSize("large");
      } else {
        setCode(String(code));
        setTitle(title);
        setMessage(message);
        setActions(actions ?? ["refresh"]);
        if (code === 403 || code === 404) {
          setTitleSize("large");
          setActions(["back"]);
        }
      }
    }

    // Show error for invalid data requests
    else if (requestError) {
      const { error, statusCode, message, correlationId = "" } = requestError;
      setCode(String(statusCode));
      setTitle(error);
      setMessage(message);
      setActions(["back", "refresh"]);
      setCorrelationId(correlationId);
      if (statusCode === 401) {
        setTitleSize("large");
        setActions(["signin"]);
      } else if (statusCode === 404) {
        setTitleSize("large");
        setActions(["back"]);
      }
    }

    // Show error for invalid data requests that don't match the expected shape
    else if (isAxiosError(error)) {
      const { message, response } = error;
      const { status = 500, statusText = "Error" } = response ?? {};
      setCode(String(status));
      setTitle(statusText);
      setMessage(message);
      setActions(["back", "refresh"]);
    }

    // Show error for invalid routes
    else if (routeError) {
      const { status, statusText, data } = routeError;
      setCode(String(status));
      setTitle(statusText);
      setMessage(data);
      setActions(["back"]);
      if (status === 404) setTitleSize("large");
    }

    // Display generic message for unknown errors
    else {
      setTitle("Application Error");
      setMessage(error instanceof Error ? (error.stack ?? String(error)) : String(error));
      setActions(["back", "refresh"]);
    }
  }, [error, appError, navigate, pathname]);

  return (
    <>
      <Navigation />
      <Container sx={{ pt: 4 }}>
        <ErrorMessageCard
          code={code}
          title={title}
          titleSize={titleSize}
          message={message}
          correlationId={correlationId}
          actions={actions}
        />
      </Container>
    </>
  );
}
