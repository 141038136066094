import {
  Typography,
  Stack,
  Grid,
  Button,
  Alert,
  Link,
  AlertTitle,
  Skeleton,
} from "@mui/material";
import { useWatch } from "react-hook-form";

import { CampusLocation, PaymentMode } from "@packages/types";
import { AUDFormat } from "@packages/utils";

import { useMaintenanceMode } from "~/hooks/maintenance-mode";
import { useApplicationFee } from "~/hooks/payment";
import { useApplicationRouteData } from "~/hooks/route-data";
import { FormSection } from "~/components/form/FormSection";
import { useAuthState } from "~/components/auth/AuthProvider";
import { FormCheckbox } from "~/components/form/FormCheckbox";
import { FormTextField } from "~/components/form/FormTextField";
import { Timestamp } from "~/components/core/Timestamp";

import {
  type AgentDeclarationFields,
  UseAgentDeclarationForm,
} from "./useAgentDeclarationForm";

export interface AgentDeclarationFormProps {
  form: UseAgentDeclarationForm;
  disabled?: boolean;
  campusLocation?: string;
  /** Calback to notify parent component that the form has been submitted successfully. */
  onSuccessfulSubmit?: () => void;
}

export function AgentDeclarationForm(props: AgentDeclarationFormProps) {
  const { campusLocation, onSuccessfulSubmit } = props;
  const { formRef, form, submitted, error, onValidSubmit, onInvalidSubmit } = props.form;
  const { control, handleSubmit, setValue } = form;

  const [user] = useAuthState();

  const fee = useApplicationFee(campusLocation as CampusLocation);

  const { active: maintenanceModeActive, maintenanceInterval } =
    useMaintenanceMode().data ?? {};
  const {
    application: {
      application: {
        payment: { mode: paymentMode },
      },
    },
  } = useApplicationRouteData();
  const disableSubmitFeeWaiverCodeApplication =
    maintenanceModeActive && paymentMode === PaymentMode.ApplicationFeeWaiverCode;

  const disabled = props.disabled || disableSubmitFeeWaiverCodeApplication || submitted;

  const [officialDocumentationPending, officialDocumentationSighted] = useWatch({
    control,
    name: [
      "checklist.officialDocumentationPending",
      "checklist.officialDocumentationSighted",
    ],
  });

  const onValidSubmitHandler = async (data: AgentDeclarationFields) => {
    // DANO-277: this should be disabled in the UI but check again just in case
    if (disableSubmitFeeWaiverCodeApplication) return;
    await onValidSubmit(data);
    onSuccessfulSubmit?.();
  };

  return (
    <Stack
      component="form"
      ref={formRef}
      gap={3}
      onSubmit={handleSubmit(onValidSubmitHandler, onInvalidSubmit)}
    >
      <FormSection>
        <FormSection.Title>Application Checklist</FormSection.Title>
        <FormSection.Well>
          <Typography variant="body1" mb={1}>
            Before submitting the application, please ensure the following:
          </Typography>
          <FormSection.List pl={3}>
            <Typography variant="body1" component="li">
              All applicable sections of the application form are completed
            </Typography>
            <Typography variant="body1" component="li">
              The{" "}
              {fee ? (
                AUDFormat.format(fee.amount)
              ) : (
                <>
                  $
                  <Skeleton variant="text" width={50} sx={{ display: "inline-block" }} />
                </>
              )}{" "}
              application fee (non-refundable) is paid
            </Typography>
            <Typography variant="body1" component="li">
              Proof of English proficiency is provided
            </Typography>
            <Typography variant="body1" component="li">
              Certified and translated copies of academic records / transcripts / grading
              scales are provided
            </Typography>
            <Typography variant="body1" component="li">
              Attached copy of the personal details section of the student's passport or a
              copy of the birth certificate
            </Typography>
          </FormSection.List>
        </FormSection.Well>
      </FormSection>
      <FormSection>
        <FormSection.Title>Partner Declaration</FormSection.Title>
        {!disableSubmitFeeWaiverCodeApplication && (
          <>
            <Typography variant="body1">
              On behalf of <strong>{user?.agency.name}</strong>, I declare the following:
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                display="block"
              >
                Tick the relevant checkboxes below
              </Typography>
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Stack gap={3} mt={1}>
              <FormCheckbox
                checkboxProps={{ sx: { my: -1 } }}
                sx={{ mt: -1 }}
                control={control}
                name="checklist.consentGiven"
                disabled={disabled}
                label={
                  <>
                    I have the applicant's consent to apply to Monash University on their
                    behalf.
                  </>
                }
              />
              <FormCheckbox
                checkboxProps={{ sx: { my: -1 } }}
                sx={{ mt: -1 }}
                control={control}
                name="checklist.contactDetailsVerified"
                disabled={disabled}
                label={
                  <>
                    I have provided the applicant's contact details as required. I
                    understand that providing false information has potential civil and
                    criminal consequences and may place me in breach of my agreement with
                    Monash University and may lead to termination of our contract and/or
                    access to the Monash Partner Portal.
                  </>
                }
              />
              <FormCheckbox
                checkboxProps={{ sx: { my: -1 } }}
                sx={{ mt: -1 }}
                control={control}
                name="checklist.officialDocumentationSighted"
                disabled={disabled}
                onBeforeChange={(_, checked) => {
                  if (checked && officialDocumentationPending) {
                    setValue("checklist.officialDocumentationPending", false);
                  }
                }}
                label={
                  <>
                    All of the applicant's official documentation provided to Monash
                    University has been sighted and verified as authentic. This will
                    include any further documentation that may have to be provided to
                    Monash University in support of an applicant's application. Any
                    English proficiency tests such as Academic IELTS, Internet-based TOEFL
                    or Pearson Test of English (Academic) have not been verified as
                    authentic.
                  </>
                }
              />
              <FormCheckbox
                checkboxProps={{ sx: { my: -1 } }}
                sx={{ mt: -1 }}
                control={control}
                name="checklist.officialDocumentationPending"
                disabled={disabled}
                onBeforeChange={(_, checked) => {
                  if (checked && officialDocumentationSighted) {
                    setValue("checklist.officialDocumentationSighted", false);
                  }
                }}
                label={
                  <>
                    Any document that has not yet been sighted and verified as authentic
                    will be provided to Monash University once they have been verified as
                    authentic by our office.
                  </>
                }
              />
              <FormCheckbox
                checkboxProps={{ sx: { my: -1 } }}
                sx={{ mt: -1 }}
                control={control}
                name="checklist.onlineResultsVerified"
                disabled={disabled}
                label={
                  <>
                    The applicant has accessed their online data results directly from the
                    awarding institution's website using their personal login details.
                    These online results have been printed and provided to Monash
                    University as authentic copies.
                  </>
                }
              />
            </Stack>
            <Grid container spacing={2} sx={{ my: 1 }}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  fullWidth
                  readOnly
                  control={control}
                  name="officeBranch"
                  label="Office / Branch"
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  fullWidth
                  readOnly
                  control={control}
                  name="name"
                  label="Name of staff member lodging this application"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </>
        )}

        {disableSubmitFeeWaiverCodeApplication && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Fee waiver code application submission unavailable</AlertTitle>
              <Typography variant="body2" gutterBottom>
                Partner Portal is currently undergoing maintenance. The fee waiver code
                payment option will be unavailable until the maintenance period ends on{" "}
                <Timestamp format="cccc h:mm aa, do MMMM yyyy timezone">
                  {maintenanceInterval?.endTime}
                </Timestamp>
                . Since this application uses the fee waiver code payment option, it
                cannot currently be submitted.
              </Typography>
              <Typography variant="body2">
                Other payment methods should still work as expected. If the fee waiver
                code payment option is preferred, please submit this application after the
                end of the maintenance period.
              </Typography>
            </Alert>
          </Grid>
        )}

        <Button variant="contained" color="primary" type="submit" disabled={disabled}>
          Submit application
        </Button>
      </FormSection>
    </Stack>
  );
}

export const CUSTOM_AGENT_TRANSFER_ERROR_MESSAGE = (
  <>
    <Typography variant="inherit" paragraph>
      This applicant is already affiliated with another agency.
    </Typography>
    <Typography variant="inherit" paragraph>
      To change agents, the applicant needs to complete an{" "}
      <Link
        href="https://www.monash.edu/__data/assets/pdf_file/0010/116758/nominationofagentform.pdf"
        target="_blank"
        rel="noopener"
      >
        agent transfer request
      </Link>
      . Please provide the agent transfer request form to the applicant and have them
      submit it as per the instructions on the form.
    </Typography>
    <Typography variant="inherit" gutterBottom>
      Once this transfer request has been processed by Monash University Central
      Admissions, you will be informed via email and should the agent transfer request be
      accepted, the applicant will appear in your Partner Portal account.
    </Typography>
  </>
);
