import { z } from "zod";
import { useState } from "react";
import {
  Control,
  UseFormClearErrors,
  useFormState,
  useWatch,
  UseFormSetValue,
} from "react-hook-form";
import { useRevalidator } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { Alert, AlertTitle, Button, Grid, Skeleton, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import {
  CampusLocation,
  CodeStringNullableSchema,
  PaymentMode,
  isApplicationStatusValidForFeeWaiverCodeUpdate,
} from "@packages/types";
import { AUDFormat } from "@packages/utils";

import { createAddIssue, doesObjectHaveTrueValues } from "~/utils";
import { IApplicationPayment, IFeeWaiverCode } from "~/api";
import { useReferenceData } from "~/hooks/reference-data";
import { useApplicationFee, useCreatePaymentUrl } from "~/hooks/payment";
import { useUpdateFeeWaiverCode, useVerifyFeeWaiverCode } from "~/hooks/application";
import { useMaintenanceMode } from "~/hooks/maintenance-mode";
import { Timestamp } from "~/components/core/Timestamp";
import { FormSection } from "~/components/form/FormSection";
import { FormRadioGroup } from "~/components/form/FormRadioGroup";
import { FormTextField } from "~/components/form/FormTextField";
import { ButtonLoadingSpinner } from "~/components/core/ButtonLoadingSpinner";
import { ApplicantDetailLabel } from "~/components/applicant/ApplicantDetailsCard";
import { useNotification } from "~/components/core/NotificationProvider";

import { ApplicationFields } from "./useApplicationForm";

export interface ApplicationFeesFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  clearErrors: UseFormClearErrors<ApplicationFields>;
  onSave: () => Promise<{ valid: boolean; success: boolean }>;
  refreshForm: (successMessage?: string) => Promise<void>;
  disabled?: boolean;
  applicationId: string;
  campusLocation: string;
  payment?: IApplicationPayment;
}

export function ApplicationFeesForm(props: ApplicationFeesFormProps) {
  const {
    control,
    setValue,
    clearErrors,
    onSave,
    refreshForm,
    disabled,
    applicationId,
    campusLocation,
    payment,
  } = props;

  const [paymentMethodCode, feeWaiverCode, applicationStatus] = useWatch({
    control,
    name: ["fees.paymentMethod.code", "fees.feeWaiverCode", "applicationStatus"],
  });

  const formState = useFormState({ control });
  const isDirty = doesObjectHaveTrueValues(formState.dirtyFields);

  const feePaymentModeOptions = useReferenceData("ApplicationFeePaymentModes");
  const fee = useApplicationFee(campusLocation as CampusLocation);
  const paymentUrl = useCreatePaymentUrl();

  const feeWaiverVerification = useVerifyFeeWaiverCode(feeWaiverCode ?? "");
  const feeWaiverVerificationStatus = feeWaiverVerification.data?.status ?? "not_found";

  const feeWaiverUpdate = useUpdateFeeWaiverCode(applicationId);

  const { active: maintenanceModeActive, maintenanceInterval } =
    useMaintenanceMode().data ?? {};

  // get errors from form state and display fee waiver alert
  const { errors } = useFormState({ control, name: "fees" });
  const formStateCustomError =
    errors.fees?.type === "custom" ? errors.fees.message : null;

  const showFeeWaiverCodeAlert =
    (feeWaiverVerification.isFetched && feeWaiverVerification.data) ||
    !!formStateCustomError;

  // clear payment info if the user changes the payment method
  function onChangePaymentMethod() {
    if (disabled || payment?.isPaid) return;
    Object.keys(EmptyPaymentInfo).map((keyString) => {
      const key = keyString as keyof PaymentInfo;
      if (key !== "paymentMethod") setValue(`fees.${key}`, EmptyPaymentInfo[key]);
    });
  }

  async function onUpdateFeeWaiverCode() {
    if (isDirty) {
      try {
        showNotification({
          type: "loading",
          message: "Updating fee waiver code...",
        });
        await feeWaiverUpdate.mutateAsync(feeWaiverCode);
        refreshForm("Fee waiver code updated successfully.");
      } catch (error) {
        // send error to Sentry
        Sentry.captureException(error, {
          tags: { source: "ApplicationFeesForm.onUpdateFeeWaiverCode" },
        });

        showErrorAlert(error, "Unable to update fee waiver code");
      }
    }
  }
  const feeWaiverCodeCanUpdate =
    isApplicationStatusValidForFeeWaiverCodeUpdate(applicationStatus);

  const feeWaiverCodeActionButton = feeWaiverCodeCanUpdate ? (
    <Button
      // some additional styling required as endAdornment breaks the styling of the spinner
      // sx added to control padding, and spinner added as endIcon.
      sx={{ px: feeWaiverUpdate.isPending ? 3.5 : 2 }}
      variant="contained"
      disabled={
        !feeWaiverCode.length ||
        feeWaiverUpdate.isPending ||
        !feeWaiverCodeCanUpdate ||
        (feeWaiverCodeCanUpdate && !isDirty) ||
        payment?.isPaid
      }
      onClick={onUpdateFeeWaiverCode}
      endIcon={<ButtonLoadingSpinner show={feeWaiverUpdate.isPending} />}
    >
      Update
    </Button>
  ) : (
    <Button
      // some additional styling required as endAdornment breaks the styling of the spinner
      // sx added to control padding, and spinner added as endIcon.
      sx={{ px: feeWaiverVerification.isFetching ? 3.5 : 2 }}
      variant="contained"
      disabled={
        !feeWaiverCode.length ||
        feeWaiverVerification.isFetching ||
        disabled ||
        payment?.isPaid
      }
      onClick={() => feeWaiverVerification.refetch()}
      endIcon={<ButtonLoadingSpinner show={feeWaiverVerification.isFetching} />}
    >
      Verify
    </Button>
  );

  const { showNotification, showErrorNotification, showErrorAlert } = useNotification();
  const [saving, setSaving] = useState(false);

  async function onPayApplicationFee() {
    if (isDirty) {
      setSaving(true);
      const { valid, success } = await onSave?.();

      if (!valid) {
        setSaving(false);
        showNotification({
          type: "warning",
          message:
            "There are incomplete sections in this application that need to be filled in before beginning the payment process.",
        });
        return;
      }

      if (!success) {
        // An error message will be shown from the save error
        // So we just need to hide the loading spinner and abort
        setSaving(false);
        return;
      }
    }

    try {
      showNotification({
        type: "loading",
        message: "Redirecting you to the payment gateway...",
      });
      const { url } = await paymentUrl.mutateAsync(applicationId);
      window.location.href = url;
    } catch (error) {
      // send error to Sentry
      Sentry.captureException(error, {
        tags: { source: "ApplicationFeesForm.onPayApplicationFee" },
      });

      showErrorNotification(error, "Failed to open payment gateway");
    } finally {
      setSaving(false);
    }
  }

  const revalidator = useRevalidator();
  const revalidating = revalidator.state === "loading";

  return (
    <>
      <FormSection.Well mt={1}>
        <Typography variant="body1">
          International applicants are required to pay a{" "}
          {fee ? (
            AUDFormat.format(fee.amount)
          ) : (
            <>
              $<Skeleton variant="text" width={50} sx={{ display: "inline-block" }} />
            </>
          )}{" "}
          application fee.
        </Typography>
      </FormSection.Well>
      <Grid container spacing={2}>
        {maintenanceModeActive && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Fee waiver code payment option unavailable</AlertTitle>
              <Typography variant="body2" gutterBottom>
                Partner Portal is currently undergoing maintenance. The fee waiver code
                payment option will be unavailable until the maintenance period ends on{" "}
                <Timestamp format="cccc h:mm aa, do MMMM yyyy timezone">
                  {maintenanceInterval?.endTime}
                </Timestamp>
                .
              </Typography>
              <Typography variant="body2">
                Other payment methods should still work as expected.
              </Typography>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormRadioGroup
            control={control}
            name="fees.paymentMethod.code"
            disabled={disabled || Boolean(payment?.transactionReference)}
            loading={feePaymentModeOptions.isPending}
            options={feePaymentModeOptions.data?.map((option) => ({
              ...option,
              disabled:
                disabled ||
                // disable the Application Fee Waiver Code option if the maintenance mode is on
                (maintenanceModeActive &&
                  option.value === PaymentMode.ApplicationFeeWaiverCode),
              onClick: onChangePaymentMethod,
            }))}
          />
        </Grid>
        {paymentMethodCode === PaymentMode.WesternUnion &&
          (payment?.transactionReference ? (
            <Grid item xs={12}>
              <ApplicantDetailLabel
                label="Fee Status"
                error={!payment.isPaid}
                value={payment.isPaid ? "Paid" : "Incorrect amount paid"}
              />
              <ApplicantDetailLabel
                label="Payment Date"
                value={
                  payment.datePaid
                    ? new Date(payment.datePaid).toLocaleDateString()
                    : "Date not provided"
                }
              />
              <ApplicantDetailLabel
                label="Transaction Reference"
                value={payment.transactionReference}
              />
              <ApplicantDetailLabel
                label="Amount"
                value={
                  payment.transactionAmount
                    ? AUDFormat.format(payment.transactionAmount)
                    : undefined
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                {payment?.isPending ? (
                  <Alert severity="info">
                    <AlertTitle>Payment confirmation pending</AlertTitle>
                    <Typography variant="body2" paragraph>
                      Our records indicate that a payment for this application may have
                      already been submitted. Please click the refresh button below to
                      check if the payment has been confirmed.
                    </Typography>
                    <Button
                      sx={{ alignSelf: "self-start" }}
                      variant="outlined"
                      disabled={revalidating}
                      onClick={() => revalidator.revalidate()}
                      endIcon={<ButtonLoadingSpinner show={revalidating} />}
                    >
                      Refresh
                    </Button>
                  </Alert>
                ) : (
                  <Alert severity="warning">
                    <AlertTitle>Pay application fee</AlertTitle>
                    <Typography variant="body2">
                      Click on the button below to pay the application fee via Convera
                      (previously Western Union).
                    </Typography>
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={saving || paymentUrl.isPending}
                  endIcon={
                    saving || paymentUrl.isPending ? (
                      <ButtonLoadingSpinner show />
                    ) : (
                      <LaunchIcon color="inherit" fontSize="small" />
                    )
                  }
                  onClick={onPayApplicationFee}
                >
                  Pay Application Fee
                </Button>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1.5 }}>
                  {isDirty ? (
                    // Use &nbsp; to avoid having orphans (typesetting)
                    <>
                      To ensure we have up-to-date payment details, clicking this button
                      will save your application before redirecting to the
                      payment&nbsp;gateway.
                    </>
                  ) : (
                    <>
                      Clicking this button will redirect you to the payment&nbsp;gateway.
                    </>
                  )}
                </Typography>
              </Grid>
            </>
          ))}
        {paymentMethodCode === PaymentMode.ApplicationFeeWaiverCode &&
          !maintenanceModeActive && (
            <>
              {!disabled && !payment?.isPaid && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    <Typography variant="body2">
                      Enter a fee waiver code below to waive the application fee for the
                      student. You may click the "verify" button to check its validity
                      prior to submitting the application.
                    </Typography>
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormTextField
                  control={control}
                  name="fees.feeWaiverCode"
                  label="Fee waiver code"
                  disabled={
                    feeWaiverVerification.isFetching ||
                    payment?.isPaid ||
                    (disabled && !feeWaiverCodeCanUpdate)
                  }
                  onBeforeChange={() => showFeeWaiverCodeAlert && clearErrors("fees")}
                  sx={{ minWidth: "45%" }}
                  InputProps={{
                    endAdornment: feeWaiverCodeActionButton,
                  }}
                  helperText={
                    feeWaiverCodeCanUpdate &&
                    'Type in a new fee waiver code and click "Update".'
                  }
                />
              </Grid>
              {showFeeWaiverCodeAlert && (
                <Grid item xs={12}>
                  {displayFeeWaiverCodeAlert(
                    formStateCustomError ?? feeWaiverVerificationStatus,
                  )}
                </Grid>
              )}
            </>
          )}
        {paymentMethodCode === PaymentMode.Other && !payment?.isPaid && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Attach files</AlertTitle>
              <Typography variant="body2">
                If you have paid the application fee using an alternative method, then
                please attach proof of payment in the supporting documents section of this
                application.
              </Typography>
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function displayFeeWaiverCodeAlert(status: string) {
  switch (status as IFeeWaiverCode["status"]) {
    case "usable":
      return (
        <Alert severity="success">
          <Typography variant="body2">
            This fee waiver code is valid at this time.
          </Typography>
        </Alert>
      );
    case "expired":
      return (
        <Alert severity="error">
          <Typography variant="body2">
            This fee waiver code has expired. Please enter a valid code to save this
            application.
          </Typography>
        </Alert>
      );
    case "redeemed":
      return (
        <Alert severity="error">
          <Typography variant="body2">
            This fee waiver code has already been redeemed. Please enter a valid code to
            save this application.
          </Typography>
        </Alert>
      );
    case "not_found":
    default:
      return (
        <Alert severity="error">
          <Typography variant="body2">
            This fee waiver code does not exist. Please enter a valid code to save this
            application.
          </Typography>
        </Alert>
      );
  }
}

ApplicationFeesForm.draftSchema = z.object({
  paymentMethod: CodeStringNullableSchema,
  feeWaiverCode: z.string(),
  // We only use this value during validation
  _isPaid: z.boolean().optional(),
});

ApplicationFeesForm.submitSchema = ApplicationFeesForm.draftSchema.superRefine(
  ({ paymentMethod, feeWaiverCode, _isPaid }, ctx) => {
    // Skip validation if the application fees are marked as paid
    if (_isPaid) return;

    const addIssue = createAddIssue(ctx);

    if (!paymentMethod?.code)
      addIssue("Please select a payment method", "paymentMethod.code");

    if (paymentMethod?.code === PaymentMode.ApplicationFeeWaiverCode && !feeWaiverCode)
      addIssue(
        "Please provide a fee waiver code or select a different payment option",
        "feeWaiverCode",
      );
  },
);

export type PaymentInfo = z.infer<typeof ApplicationFeesForm.draftSchema>;

export const EmptyPaymentInfo: PaymentInfo = {
  paymentMethod: { code: "" },
  feeWaiverCode: "",
};
