import { useQuery } from "@tanstack/react-query";

import { DATA_STALE_TIME } from "~/config/constants";
import { GetApi, MaintenanceModeApi } from "~/api";

export function useMaintenanceMode() {
  return useQuery({
    queryKey: [useMaintenanceMode.name],
    queryFn: () => GetApi(MaintenanceModeApi).getMaintenanceMode(),
    staleTime: DATA_STALE_TIME,
  });
}
